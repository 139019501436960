<template>
    <div class="translation-score-index">
        <div class="flex flex-justify-center zindex-9999">
            <language-selector ref="languageSelectorDom" :default="{source: form.originValue, target: form.targetValue}" :auto="autoFlag" :lang="autoLang" @change="languageSelectedChange"></language-selector>
            <div class="term-setting margin-l-15">
                <div class="setting-btn" @click="showTermSettingEvent"><svg-icon name="ib-setting"></svg-icon> 术语定制</div>
                <div class="setting-switch margin-l-10">
                    <next-switch v-model="userEngineConfig.enabled" size="small" @change="setUserConfig"></next-switch>
                </div>
            </div>
        </div>
        <div class="configuration-content">
            <div class="engine-wrapper">
                <el-tabs v-model="activeDomain" @tab-click="domainTabClick">
                    <template v-for="domain in domainList">
                        <el-tab-pane :label="domain.name" :name="domain.key" :key="domain.key"></el-tab-pane>
                    </template>
                </el-tabs>
                <div class="setting-content" v-if="activeDomain === 'commonly'">
                    <el-popover  placement="bottom-start" trigger="manual" v-model="engineRecommendTipsShow">
                        <div class="padding-8">
                            <div class="fonts-12 color-FF9900 bg-fdf6e7 padding-x-10 padding-y-8 radius-50 text-center">你可以配置自己的常用引擎</div>
                            <div class="margin-t-10">
                                <img src="../../../../static/images/engine/engine-recommend-img.png" alt="engine recommend" style="width: 357px;"/>
                            </div>
                            <div class="margin-t-20 text-center">
                                <el-button plain round size="mini" @click="handleCloseEngineConfigTips">关闭</el-button>
                                <el-button plain round size="mini" @click="handleOpenEngineConfig">开始配置</el-button>
                            </div>
                        </div>
                        <el-button slot="reference" class="fonts-12" type="text" style="color: #2979FF;" @click="handleOpenEngineConfig"><svg-icon name="ib-setting"></svg-icon> 配置引擎</el-button>
                    </el-popover>
                    <div class="flex flex-align-center">
                        <span class="margin-r-10 fonts-12">批量翻译</span>
                        <next-switch v-model="batchTranslateEnable" size="small" @change="batchTranslateEnableChange"></next-switch>
                    </div>
                </div>
                <div class="engine-list">
                    <template v-for="(engine, index) in engineList">
                        <div class="item" :class="{'active': selectedEngine && selectedEngine.key === engine.key}" :key="index" @click="engineClickEvent(engine)">
                            <div class="name">
                                <svg-icon :name="`ie-${engine.icon}`"></svg-icon>
                                <span class="text-omission">{{engine.name}}</span>
                            </div>
                            <template v-if="activeDomain === 'personal'">
                                <el-tooltip content="查看引擎详情" placement="top">
                                    <div class="more-btn" @click.stop="gotoDetailPage(engine)">
                                        <svg-icon name="ib-arrow-right" className="fonts-16"></svg-icon>
                                    </div>
                                </el-tooltip>
                            </template>
                            <div class="triangle-mark" v-if="engine.proUsed"><span>PRO</span></div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="input-wrapper">
                <el-input v-model="form.sourceText" type="textarea" resize="none" @input="sourceTextInputEvent" placeholder="粘贴一段文字，快速对比不同引擎翻译结果，每次最多2000字"></el-input>
                <div class="input-footer">
                    <div>
                        <div class="color-link cursor-pointer" @click="randomDocBtnEvent" v-if="randomDocBtnShow"><svg-icon name="ib-refresh"></svg-icon> {{form.sourceText ? '换一换':'试一试样例'}}</div>
                    </div>
                    <div>{{randomSentenceLable}}</div>
                    <div v-if="form.sourceText.length > 0">
                        <span class="margin-r-18">{{form.sourceText.length}}/2000</span>
                        <el-button type="primary" round class="send-btn" @click="sendBtnEvent">
                            <svg-icon name="ib-send"></svg-icon>
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="close-btn" @click="form.sourceText = ''" v-if="form.sourceText">
                <svg-icon name="ib-close"></svg-icon>
            </div>
        </div>
        <div class="result-setting" v-if="historyList.length">
            <div class="flex flex-align-center">
                <span class="color-666666 margin-r-10">AI质量评估</span>
                <next-switch v-model="qualitySwitch" size="small"></next-switch>
            </div>
            <div class="flex flex-align-center margin-l-10">
                <span class="color-666666 margin-r-10">展示差异</span>
                <next-switch v-model="diffSwitch" size="small"></next-switch>
            </div>
            <el-divider direction="vertical" class="margin-x-15 bg-ccc"></el-divider>
            <div>
                <el-tooltip content="清空历史记录" placement="top">
                    <el-button type="text" style="padding: 0;" @click="historyList = []"><svg-icon name="ib-trash-o" class="fonts-16"></svg-icon></el-button>
                </el-tooltip>
            </div>
        </div>
            
        <div class="result-list">
            <template v-for="(item, index) in historyList">
                <div class="item" :key="index">
                    <div class="engine-action-content">
                        <div class="engine-info">
                            <svg-icon :name="`ie-${item.icon}`" class="fonts-24"></svg-icon>
                            <span class="fonts-15 margin-l-8">{{item.name}}</span>
                        </div>
                        <div class="action-info">
                            <template v-if="item.targetText">
                                <div class="score" v-if="qualitySwitch">{{item.targetScore}}分</div>
                                <el-button round plain size="mini" class="action-btn" :class="{'active': item.polishShow}" :loading="item.polishLoading" @click="polishHistoryItem(item)">
                                    <svg-icon name="ib-brush" v-if="!item.polishLoading"></svg-icon> {{item.polishText? '再次润色' : '润色'}}
                                </el-button>
                                <el-button round plain size="mini" class="action-btn" @click="copyHistoryItem(item)"><svg-icon name="ib-copy"></svg-icon> 复制</el-button>
                            </template>
                            <el-button type="text" class="close-btn" @click="deleteHistoryItem(index)"><svg-icon name="ib-close"></svg-icon></el-button>
                        </div>
                    </div>
                    <template v-if="!item.targetLoading">
                        <div class="translation-content" v-html="diffSwitch && item.difference ? item.difference : item.targetHtml"></div>
                    </template>
                    <template v-else>
                        <div class="translation-content">
                            <p><i class="el-icon-loading"></i> 翻译中，请稍等</p>
                        </div>
                    </template>
                    <div class="polish-wrapper" v-if="item.polishShow">
                        <div class="polish-title">
                            <img src="../../../../static/images/tools/polish-brush-img.png" alt="brush" />
                            <span class="margin-l-10">润色</span>
                        </div>
                        <div class="polish-content" v-html="item.polishHtml"></div>
                    </div>
                </div>
            </template>
        </div>
        <term-setting ref="termSettingDom" :lang="{source: form.originValue || 'zh', target: form.targetValue}"></term-setting>
        <engine-config ref="engineConfigDom" @saved="onEngineConfigSaved"></engine-config>
    </div>
</template>
<script>
import $ from "jquery";
import { mapState } from 'vuex';
import tools from '@/utils/tools';
import { myEngineApi, projectApi, docApi, wsApi } from '@/utils/api';
import IWebSocket from '@/utils/websocket';
import LanguageSelector from '@/components/LanguageSelector';
import TermSetting from '../../engine/components/TermSetting';
import NextSwitch from '@/components/NextSwitch';
import EngineConfig from '@/views/tools/components/EngineConfig';

export default {
    components: {
        LanguageSelector, TermSetting, NextSwitch, EngineConfig, 
    },
    data() {
        return {
            activeDomain: 'commonly',
            domainList: [
                {key: 'commonly', name: '常用引擎'},
                {key: 'llm', name: '大模型翻译'},
                {key: 'general', name: 'NMT机器翻译'},
                {key: 'personal', name: '个性化机器翻译'},
            ],
            form: {
                sourceText: '',
                originValue: '',
                targetValue: 'en',
            },
            autoFlag: true,
            autoLang: '',
            engineList: [],
            selectedEngine: null,

            userEngineConfig: {},
            userTermList: [],
            batchTranslateEnable: true,
            qualitySwitch: true,
            diffSwitch: true,
            historyList: [],

            sourceTextInputTimer: null,

            randomDocBtnShow: false,
            randomSentences: [],
            randomSentenceLable: '',
            engineRecommendTipsShow: false,

            polishLimitCount: 3,
            
            trafficLimitedMsg: null,
            socket: null,
        }
    },
    computed: {
        ...mapState({
            activeOrg: state => state.org.activeOrg,
            userInfo: state => state.user.userData,
        }),
        finishedHistorySize: function () {
            return this.historyList.filter(item => !item.targetLoading).length
        }

    },
    methods:{
        async languageSelectedChange(val){
            this.form.targetValue = val.target.key;
            this.form.originValue = val.source.key;
            this.historyList = [];
            this.history = {};
            this.autoLang = val.lang ? val.lang.key : null;
            if(val.auto && val.lang === null){
                if (this.form.sourceText) {
                    await this.detectionTextLanguage();
                    // await this.getEngineList();
                }
            }
            await this.getEngineList();
            
            // this.startTranslateText();
            this.getRandomSentences();
        },
        sourceTextInputEvent(val){
            if (this.sourceTextInputTimer) {
                clearTimeout(this.sourceTextInputTimer);
                this.sourceTextInputTimer = null;
            }
            this.sourceTextInputTimer = setTimeout(async () => {
                if (val.length) {
                    this.historyList = [];
                    let langSelector = this.$refs.languageSelectorDom.getObjectValues();
                    if (langSelector.auto) {
                        await this.detectionTextLanguage();
                    }
                    await this.getEngineList();
                    // this.startTranslateText();
                    this.getRandomSentences();
                }
            }, 500);
        },
        detectionTextLanguage() {
            // if(!this.form.sourceText) return;
            return new Promise((resolve, reject) => {
                let url = docApi.getTextLanguage;
                let postData = {
                    text: this.form.sourceText,
                };
                this.$ajax.post(url, postData).then(res => {
                    if (res.status === 200){
                        if (res.data !== 'unknown'){
                            this.form.originValue = res.data;
                            this.autoLang = res.data;
                        }else{
                            this.form.originValue = 'zh';
                            this.autoLang = 'zh';
                        }
                        if (this.form.originValue === this.form.targetValue){
                            if (this.form.originValue === 'zh'){
                                this.form.targetValue = 'en';
                            }else{
                                this.form.targetValue = 'zh';
                            }
                        }
                        resolve(res.data);
                    }
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getEngineList() {
            this.engineList = [];
            return new Promise((resolve, reject) => {
                let sourceLang = this.form.originValue ? this.form.originValue : this.autoLang ? this.autoLang : 'zh';
                let url = `${projectApi.queryEnginesByLang}?sourceLang=${sourceLang}&targetLang=${this.form.targetValue}&domain=${this.activeDomain}`;
                this.$ajax.get(url).then(res => {
                    if (res.status === 200) {
                        this.engineList = res.data[0].engines;
                        resolve(res.data[0].engines);
                    }
                }).catch(error => {
                    reject(error);
                });
            })
            
        },
        sendBtnEvent(){
            this.startTranslateText();
        },
        async startTranslateText(){
            if(!this.form.sourceText) return;
            let waitFullEngineList = [];
            if(this.activeDomain === 'commonly' && this.batchTranslateEnable) {
                waitFullEngineList = this.engineList;
            }else {
                if (this.selectedEngine) {
                    waitFullEngineList = [this.selectedEngine];
                }
            }
            if(waitFullEngineList.length === 0) {
                this.$message.error('请选择翻译引擎');
                return;
            }
            let waitEngineList = [];
            let engines = [];
            for(let i = 0; i < waitFullEngineList.length; i++){
                let findIndex = this.historyList.findIndex(item => item.key === waitFullEngineList[i].key);
                if (findIndex === -1) {
                    waitEngineList.push({
                        ...waitFullEngineList[i], 
                        targetLoading: true, 
                        targetText: '', 
                        targetHtml: '', 
                        difference: '',
                        polishLoading: false, 
                        polishShow: false, 
                        polishText: '', 
                        polishHtml: '',
                        polishCount: 0,
                        targetScore: 0, 
                    });
                }else {
                    if(!this.historyList[findIndex].targetText) {
                        engines.push({
                            domain: this.historyList[findIndex].domain,
                            engine: this.historyList[findIndex].key.split('@')[0],
                            engineKey: this.historyList[findIndex].key,
                            modelId: this.historyList[findIndex].modelId ? this.historyList[findIndex].modelId : undefined,
                        });
                        this.historyList.splice(findIndex, 1, {...this.historyList[findIndex], targetLoading: true});
                    }
                }
            }
            this.historyList = waitEngineList.concat(this.historyList);
            waitEngineList.map(item => {
                engines.push({
                    domain: item.domain,
                    engine: item.key.split('@')[0],
                    engineKey: item.key,
                    modelId: item.modelId ? item.modelId : undefined,
                });
            });
            if (engines.length === 0){
                return;
            }
            let termList = [];
            if(this.userEngineConfig.enabled){
                let url = `${myEngineApi.queryMyTerms}?sourceLanguage=${this.form.originValue}&targetLanguage=${this.form.targetValue}`;
                let res = await this.$ajax.get(url);
                termList = res.data.terms;
            }
            this.userTermList = termList;
            let sendParams = {
                sourceLang: this.form.originValue,
                targetLang: this.form.targetValue,
                sourceText: this.form.sourceText,
                engineConfigs: engines,
                terms: termList,
            };
            let socketUrl = `${location.protocol === 'https:'? 'wss' : 'ws'}://${location.host}${wsApi.imWebsocket}`;
            if (this.socket === null) {
                this.socket = new IWebSocket(socketUrl, data => {
                    // console.log('socket data:', data);
                    if(data.commandCode === 'TOOLKIT_MT_QUALITY_SCORE') {
                        let content = data.content;
                        if (content.success) {
                            let findHIndex = this.historyList.findIndex(item => item.key === content.data.engineKey);
                            if (findHIndex !== -1) {
                                this.historyList.splice(findHIndex, 1, {
                                    ...this.historyList[findHIndex],
                                    targetText: content.data.target,
                                    targetHtml: `<p>${content.data.target.replace(/<span class=\"term-highlight\"( [^>]*)?>/g, '').replace(/<\/span>/g, '').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replace(/\n/g,'</p><p>')}</p>`,
                                    targetLoading: false,
                                    targetScore: content.data.targetScore,
                                });
                                this.$nextTick(() => {
                                    this.getTargetCompareResult();
                                    if (this.historyList.length === this.finishedHistorySize && this.socket) {
                                        this.socket.close();
                                        this.socket = null;
                                    }
                                })
                            } 
                        }else {
                            switch (content.code){
                                case 'TrafficLimited':
                                    if(!this.trafficLimitedMsg) {
                                        this.trafficLimitedMsg = this.$message({
                                            showClose: true,
                                            type: 'error',
                                            dangerouslyUseHTMLString: true,
                                            duration: 0,
                                            message: '流量不足，<a href="/account/vip?view=char" target="_blank">去购买</a>',
                                        });
                                    }
                                    break;
                            }
                            if (this.socket) {
                                this.socket.close();
                                this.socket = null;
                            }
                        }
                    }
                });
                this.socket.connect();
            }
            console.log('[INFO]', '开始翻译 -', new Date().getTime());
            this.socket.send({
                content: sendParams,
                messageType: 'CMD',
                contentType: 'JSON',
                commandCode: 'TOOLKIT_MT_QUALITY_SCORE',
            });
        },
        getRandomSentences(){
            let langVal = this.$refs.languageSelectorDom.getObjectValues();
            this.randomSentences = [];
            this.randomDocBtnShow = false;
            if(!((langVal.auto && langVal.lang) || (!langVal.auto && this.form.originValue))){
                return;
            }
            
            let domain = this.selectedEngine && this.selectedEngine.domain;
            if(this.activeDomain === 'commonly' && this.batchTranslateEnable) {
                domain = 'general';
            }else {
                if(!this.selectedEngine){
                    return;
                }
            }

            let url = docApi.queryRandomParagraphList;
            let postData = {
                source: this.form.originValue,
                domain,
            }
            if (this.selectedEngine && this.selectedEngine.modelId) {
				postData.modelId = this.selectedEngine.modelId;
			}
            this.$ajax.post(url, postData).then(res => {
                if(res.status == 200){
                    this.randomSentences = res.data || [];
                    this.randomDocBtnShow = this.randomSentences.length > 0;
                }
            })
        },
        randomDocBtnEvent(){
            let index = Math.floor(Math.random() * this.randomSentences.length);
            this.form.sourceText = this.randomSentences[index].sourceText;
            this.randomSentenceLable = this.randomSentences[index].tags;
            this.historyList = [];
            this.$nextTick(() => {
                // this.startTranslateText();
            });
        },
        showTermSettingEvent(){
            if(!this.form.originValue){
                this.$message.error('请选择源语言');
                return;
            }
            this.$refs.termSettingDom.setShow(true);
        },
        getUserConfig(){
            let url = myEngineApi.queryUserConfig;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    this.userEngineConfig = res.data.termCustom;
                }
            })
        },
        handleOpenEngineConfig(){
            this.$refs.engineConfigDom.setShow();
            this.handleCloseEngineConfigTips();
        },
        handleCloseEngineConfigTips(){
            this.engineRecommendTipsShow = false;
            this.$ls.set('engineRecommendTipsKnowned', true);
        },
        setUserConfig(){
            let url = myEngineApi.updateTermSwitch;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    if(this.userEngineConfig.enabled){
                        this.$message.success('术语定制已开启');
                    }else{
                        this.$message.success('术语定制已关闭');
                    }
                    console.info('[INFO]', '术语定制开关已更新');
                }
            })
        },
        async domainTabClick(val){
            await this.getEngineList();
            if(this.activeDomain === 'commonly' && this.batchTranslateEnable) {
                // this.startTranslateText();
            }
        },
        async onEngineConfigSaved(){
            if(this.activeDomain === 'commonly') {
                await this.getEngineList();
                if (this.batchTranslateEnable){
                    // this.startTranslateText();
                }
            }
        },
        batchTranslateEnableChange() {
            // this.startTranslateText();
            this.getRandomSentences();
        },
        engineClickEvent(engine) {
            if(this.activeDomain === 'commonly' && this.batchTranslateEnable) {
                this.selectedEngine = null;
            } else {
                if(!engine.enabled){
                    this.$message.error('个性引擎因会员到期已下架，续订后会恢复服务~');
                    return;
                }
                if (engine.proUsed){
                    this.$message({
                        type: 'warning',
                        dangerouslyUseHTMLString: true,
                        message: 'Pro版专属引擎，升级为Pro版可用。<a href="/account/vip">去升级</a>',
                    });
                    return;
                }
                this.selectedEngine = engine;
                this.getRandomSentences();
                // this.startTranslateText();
            }
        },
        polishHistoryItem(item) {
            if(item.polishCount >= this.polishLimitCount) {
                this.$message.warning(`最多润色${this.polishLimitCount}次`);
                return;
            }
            item.polishLoading = true;
            let url = docApi.polishText;
            let postData = {
                sourceLang: this.form.originValue,
                targetLang: this.form.targetValue,
                sourceText: item.targetText,
            }
            this.$ajax.post(url, postData).then(res => {
                if (res.status === 200) {
                    item.polishText = res.data;
                    item.polishHtml = '<p>'+res.data.replace(/\n/g,'</p><p>')+'</p>';
                    item.polishShow = true;
                    item.polishCount = item.polishCount + 1;
                }
            }).finally(() => {
                item.polishLoading = false;
            });
        },
        copyHistoryItem(item){
            let self = this;
            let _text = $(`<span>${item.polishText ? item.polishText : item.targetText}</span>`).text();
            this.$copyText(_text).then(function (e) {
                self.$message.success('已复制到剪切板');
            }, function (e) {
                self.$message.error('复制到剪切板失败，请重试');
            })
        },
        deleteHistoryItem(index) {
            this.historyList.splice(index, 1);
        },
        getTargetCompareResult(){
            let tarTexts = {};
            this.historyList.map(item => {
                if(item.targetText){
                    tarTexts[item.key] = item.targetText
                }
            });
            if(Object.keys(tarTexts).length < 2) {
                return;
            }
            let postData = {
                tarTexts,
                srcText: this.form.sourceText,
                srcLang: this.form.originValue,
                tarLang: this.form.targetValue,
                tag: 'span',
                elementParams: 'class="diff-highlight"',
            }
            let url = myEngineApi.queryTargetTextHighlight;
            this.$ajax.post(url, postData).then(res => {
                if (res.status === 200){
                    let data =  res.data;
                    let dKeys = Object.keys(data);
                    dKeys.map(key => {
                        let hIndex = this.historyList.findIndex(item => item.key === key);
                        if (hIndex !== -1){
                            this.historyList.splice(hIndex, 1, {...this.historyList[hIndex], difference: `<p>${data[key].replace(/\n/g,'</p><p>')}</p>`});
                        }
                    });
                }
            });
        },
        gotoDetailPage(engine) {
            let newpage = this.$router.resolve({path: '/engine/detail', query: {id: engine.modelId}})  
            window.open(newpage.href, '_blank');
        },
    },
    created() {
        /* let query = this.$route.query;
        if(query.source){
            this.form.originValue = query.source;
            this.autoFlag = false;
        }
        if(query.target){
            this.form.targetValue = query.target;
        } */
        $('.el-main').addClass('translation-score-bg');
        this.getEngineList();
    },
    mounted() {
        if(!this.$ls.get('engineRecommendTipsKnowned')){
            this.engineRecommendTipsShow = true;
        }
        //监控浏览器窗口大小变化
        window.onresize = () => {
            //获取浏览器窗口大小
            let width = document.documentElement.clientWidth;
            // console.info('[INFO]', '窗口大小变化', width);
            if(width > 950){
                $('.engine-wrapper .el-tabs__nav-wrap').removeClass('is-scrollable');
            }
        }
    },
    beforeDestroy() {
        $('.el-main').removeClass('translation-score-bg');
        if(this.sourceTextInputTimer) {
            clearTimeout(this.sourceTextInputTimer);
            this.sourceTextInputTimer = null;
        }
        if (this.socket) {
            this.socket.close();
            this.socket === null
        }
        if(this.trafficLimitedMsg) {
           this.trafficLimitedMsg.close();
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.translation-score-index {
    max-width: 1276px;
    margin: 20px auto;
    
    .configuration-content {
        margin-top: 20px;
        padding: 30px 0;
        background-color: #ffffffB3;
        min-height: 356px;
        border-radius: 10px;
        display: flex;
        position: relative;
        .engine-wrapper {
            width: 487.3px;
            border-right: 1px dashed #cccccc;
            box-sizing: border-box;
            padding: 0 30px;
            ::v-deep .el-tabs__header {
                margin-bottom: 0;
            }
            ::v-deep .el-tabs__nav-wrap::after {
                height: 1px;
            }
            ::v-deep .el-tabs__active-bar {
                display: none;
            }
            ::v-deep .el-tabs__nav-next, 
            ::v-deep .el-tabs__nav-prev {
                line-height: 32px;
                font-size: 14px;
                // display: none;
                background-color: #fff;
            }
            ::v-deep .el-tabs__item {
                padding: 0 15px;
                height: 32px;
                line-height: normal;
                color: #999999;
                font-size: 15px;
                &.is-active {
                    color: #000000;
                    // font-weight: bold;
                }
                &:nth-child(2) {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }

            .setting-content {
                height: auto;
                margin-top: 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                ::v-deep .el-button--text {
                    padding: 0;
                }
            }
            .engine-list {
                margin-top: 20px;
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
                grid-gap: 20px;
               .item {
                    position: relative;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    height: 44px;
                    border-radius: 6px;
                    border: 1px solid transparent;
                    box-sizing: border-box;
                    background-color: #fff;
                    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.10);
                    cursor: pointer;
                    &.active {
                        border-color: #000000;
                    }
                    .name {
                        text-align: center;
                        display: flex;
                        align-items: center;
                        margin-left: 10px;
                        // justify-content: center;
                        font-size: 12px;
                        flex: 1;
                        min-width: 0;
                        line-height: 1.2;
                        svg {
                            font-size: 22px;
                            min-width: 22px;
                            margin: 0 5px;
                        }
                    }
                    .more-btn {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 0 6px 6px 0;
                        font-size: 12px;
                        padding: 0 1px;
                        color: #999999;
                        &:hover {
                            background-color: #cccccc;
                        }
                    }
                }
            }
        }
        .input-wrapper {
            flex: 1;
            position: relative;
            padding: 0px 30px;
            ::v-deep .el-textarea {
                height: calc(100% - 42px);
                .el-textarea__inner {
                    height: 100%;
                    border: none;
                    background-color: transparent;
                    padding: 0px 18px 0 0;
                    font-size: 14px;
                    line-height: 1.5;
                    color: #000000;
                    // overflow-y: scroll;
                }
            }
            .input-footer {
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 12px;
                margin-top: 10px;
            }
        }
        .close-btn {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 15px;
            background-color: #fff;
            color: #999999;
            border-radius: 0 10px 0 10px;
            &:hover {
                color: #000000;
            }
        }
        .send-btn {
            font-size: 20px;
            padding: 5px;
        }
    }
    .result-setting {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 10px 0;
        font-size: 12px;
    }
    .result-list {
        .item {
            padding: 15px 20px;
            background-color: #FFFFFF;
            border-radius: 8px;
            border: 1px solid #0000001A;
            box-sizing: border-box;
            margin-bottom: 10px;
            .engine-action-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .engine-info {
                    display: flex;
                    align-items: center;
                }
                .action-info {
                    display: flex;
                    align-items: center;
                    .score {
                        display: flex;
                        align-items: center;
                        border-radius: 20px;
                        padding: 0 15px;
                        font-size: 12px;
                        color: #00B670;
                        background-color: #E9F8F2;
                        height: 27.22px;
                        font-weight: bold;
                        margin-right: 10px;
                    }
                    .action-btn {
                        background: transparent;
                        border-color: rgba(0, 0, 0, 0.15);
                        color: #000;
                        &.active {
                            border-color: #00B670;
                            color: #00B670;
                        }
                    }
                    .close-btn {
                        color: #999999;
                        padding: 0;
                        font-size: 16px;
                        &:hover {
                            color: #000;
                        }
                    }
                }
            }
            .translation-content {
                margin-top: 15px;
                ::v-deep p {
                    font-size: 14px;
                    line-height: 24px;
                }
                ::v-deep .diff-highlight{
                    // color: #E98C02;
                    background-color: #D4E4FF;
                    font-weight: 600;
                }
            }
            .polish-wrapper {
                border-top: 1px solid #0000001A;
                box-sizing: border-box;
                margin-top: 15px;
                padding-top: 15px;
                .polish-title {
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                    img {
                        width: 24px;
                        height: 24px;
                        user-select: none;
                    }
                }
                .polish-content {
                    margin-top: 15px;
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }
    .term-setting{
        display: flex;
        align-items: center;
        height: 40px;
        box-sizing: border-box;
        border: 1px solid #e5e5e5;
        background-color: #FFFFFF;
        border-radius: 4px;
        padding: 0 15px; 
        font-size: 14px;
        .setting-btn{
            cursor: pointer;
            color: $a-color;
        }
        &:hover{
            border-color: #000000;
        }
    }
}
@media screen and (max-width: 950px) {
    .translation-score-index {
       .configuration-content {
           .engine-wrapper {
                width: 380px;
                ::v-deep .el-tabs__nav-next, 
                ::v-deep .el-tabs__nav-prev {
                    line-height: 32px;
                    font-size: 14px;
                    // display: block;
                    background-color: #FFFFFF;
                }
            }
        }
    }
}

</style>
